import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "D:/data/pc_data/firma/0_kunden/Wenke/new-website/src/components/layout.js";
import { Container } from '../components/container';
import { FancyButton } from '../components/fancyButton';
import { Spacer } from '../components/spacer';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Container mdxType="Container">
      <h1>{`Diese seite wurde nicht gefunden`}</h1>
      <Spacer height={12} mdxType="Spacer" />
      <FancyButton component="a" extraSpace href="/" mdxType="FancyButton">
Zurück zu Normalen Seite
      </FancyButton>
    </Container>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      