import React from "react"
import { createUseStyles } from "react-jss"
import classNames from "classnames"

const useStyles = createUseStyles(theme => ({
  fancyButton: {
    color: theme.palette.primary.main,
    backgroundColor: "transparent",
    padding: [10, 16],
    border: [2, "solid", theme.palette.primary.main],
    borderRadius: 20,
    fontWeight: 700,
    textTransform: "uppercase",
    outline: "none",
    transitionDuration: "300ms",
    transitionProperty: ["color", "backgroundColor"],
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.white,
    },
    cursor: 'pointer',
    textDecoration: "none",
  },
  selected: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white,
  },
  setWidth: {
    width: "90%",
    [`@media (max-width: ${theme.vars.mobileWidth}px)`]: {
      width: "100%",
    },
  },
  extraSpace: {
    padding: [10, 0],
    border: [2, "solid", "transparent"],
  },
}))

export const FancyButton = ({
                              component: Component,
                              className,
                              extraSpace,
                              children,
                              setWidth,
                              selected,
                              onPress,
                              ...props
                            }) => {
  const classes = useStyles()

  const ret = <Component onClick={onPress} className={classNames(classes.fancyButton, {
    [classes.selected]: selected, [classes.setWidth]: setWidth ?? true,
  }, className)} {...props}>
    {children}
  </Component>

  return extraSpace ? <div className={classes.extraSpace}>{ret}</div> : ret
}

FancyButton.defaultProps = {
  component: "button",
  extraSpace: false,
  selected: false,
  setWidth: true,
  onPress: () => {},
}